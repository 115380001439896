import React, { useContext } from 'react';
import './styles.scss';
import { addOverlay, OverlayEnum, SspProviderContext } from '../../did-utility';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import {
	ConsumptionElementType,
	consumptionList,
	ConsumptionListType,
	disclaimerTagsInConsumptionLists,
	getConsumptionValue,
} from '../../did-utility/src/Functions/consumptionLists';
import i18next from 'i18next';
import { engineTypes } from '../../did-utility/src/Components/Label/dataLabel';

export default function TechData(): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const vehicleDataModel = contentModel.ref('vehicle_data').asModel;
	const engine = contentModel.ref('engine').asModel;
	const engineType = engineTypes.includes(
		engine.str('engine_type', 'Default')
	)
		? (engine.str('engine_type', 'Default') as keyof ConsumptionListType)
		: ('Default' as keyof ConsumptionListType);
	const techEntries = contentModel.ref('engine').asModel.reflist('entries');
	const tyreLabels = contentModel
		.ref('vehicle_data')
		.asModel.reflist('tyre_labels');
	const hasWLTPData = vehicleDataModel
		.keys()
		.some(key => key.includes('wltp'));
	let tyreString = '';

	tyreLabels.forEach((label, index) => {
		tyreString +=
			label.asModel.str('type') +
			' ' +
			label.asModel.str('tyreDesignation') +
			(index === tyreLabels.length - 1 ? '' : ', ');
	});

	const engineDisclaimers = engine.arr('disclaimers') as Array<{
		Key: string;
		Value: string;
	}>;
	const disclaimerTags: Array<string> = [
		...disclaimerTagsInConsumptionLists(engineType),
	];
	techEntries.forEach(entry => {
		(
			entry.asModel.arr('entries') as Array<{
				Key: string;
				Value: string;
			}>
		).forEach(e => {
			const tag = e.Key.substring(
				e.Key.indexOf('#') + 1,
				e.Key.lastIndexOf('#')
			);
			const index = engineDisclaimers.findIndex(dis => dis.Key === tag);
			if (tag.length > 0 && index !== -1) {
				disclaimerTags.push(tag);
			}
		});
	});

	const getConsumption = (c: ConsumptionElementType) => {
		const disclaimerNumber =
			c.disclaimer && disclaimerTags.includes(c.disclaimer)
				? engineDisclaimers.findIndex(d => d.Key === c.disclaimer)
				: -1;
		if (c.hideIfEmpty && !vehicleDataModel.has(c.key)) {
			return <></>;
		}

		return (
			<div
				className='table-element tech-data'
				key={`tech-data-consumption-element-${c.key}`}
			>
				<p className='short start'>
					{i18next.t(c.headline)}
					{disclaimerNumber !== -1 && (
						<sup>{disclaimerNumber + 1}</sup>
					)}
				</p>
				<p className='short middle bold'>
					{getConsumptionValue(c, vehicleDataModel)}
				</p>
			</div>
		);
	};

	return (
		<>
			{tyreString && (
				<>
					<div className='table-element headline'>
						<h4 className='bold'>
							{i18next.t('TireLabelInformation')}
						</h4>
					</div>
					<div
						className='table-element'
						onClick={() => addOverlay(store, OverlayEnum.Tire)}
					>
						<p className='short start'>
							{i18next.t('TireLabelMainTires')}:
						</p>
						<p className='short bold middle with-end ellipsis'>
							{tyreString}
						</p>
						<div className='info-icon'>
							<svg className='icon-24'>
								<use xlinkHref='#system-info' />
							</svg>
						</div>
					</div>
				</>
			)}
			{hasWLTPData && (
				<>
					<div className='table-element headline'>
						<h4 className='bold'>
							{i18next.t('WltpValuesTableHeadline')}
						</h4>
					</div>
					{consumptionList.wltp &&
						consumptionList.wltp[engineType]?.map(item => (
							<div key={item.key}>{getConsumption(item)}</div>
						))}
				</>
			)}
			{techEntries.map((entry, index) => {
				const languages = Object.keys(
					i18next.options.resources as Object
				);
				let entryList;
				if (
					languages.length > 1 &&
					entry.asModel.target.entries[i18next.language]
				) {
					entryList = entry.asModel.target.entries[
						i18next.language
					] as Array<{
						Key: string;
						Value: string;
					}>;
				} else {
					entryList = entry.asModel.arr('entries') as Array<{
						Key: string;
						Value: string;
					}>;
				}
				let entryTitle;
				if (
					languages.length > 1 &&
					entry.asModel.target.name[i18next.language]
				) {
					entryTitle = entry.asModel.target.name[i18next.language];
				} else {
					entryTitle = entry.asModel.str('name');
				}
				return (
					<React.Fragment key={`entry-${index}`}>
						<div
							className={`table-element headline ${
								entryList.length < 1 ||
								(entryList.length === 1 &&
									entryList[0].Key === '')
									? 'empty'
									: ''
							}`}
						>
							<h4 className='bold'>{entryTitle}</h4>
						</div>
						{entryList.map((e, index) => (
							<div
								className={`table-element ${
									e.Key === '' ? 'empty' : ''
								}`}
								key={`entry-e-${index}`}
							>
								<p className='short start'>
									{e.Key !== '' && e.Key}
								</p>
								<p className='short bold'>
									{e.Value !== '' && e.Value}
								</p>
							</div>
						))}
					</React.Fragment>
				);
			})}
		</>
	);
}
