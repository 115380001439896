import React, { useContext } from 'react';
import './styles.scss';
import { addOverlay, OverlayEnum, SspProviderContext } from '../../did-utility';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import {
	UpperTableElement,
	UpperTableElementNedcWltp,
	UpperTableElementNumber,
} from './upperTableElement';
import i18next from 'i18next';
import { checkHtml } from '../../did-utility/src/Functions/checkHTML';
import { TeaserImage } from '../../did-utility/src/Components/TeaserImage';
import { showInfoIcon } from '../../did-utility/src/Functions/showInfoIcon';
import { getSetup } from '../../did-utility/src/Functions/getSetup';

export default function Overview(): JSX.Element {
	const store = useContext(SspProviderContext);
	const contentModel = store.state.content
		? store.state.content.asModel
		: new SSPContentVO('').asModel;
	const vehicleDataModel = contentModel.ref('vehicle_data').asModel;
	const engine = contentModel.ref('engine').asModel;
	const highlightEquip = contentModel.reflist('highlight_equip');
	const usedCar = store.state.content?.contentType === 'UsedPricesheet';
	const setup = getSetup();
	const moreData = (
		<h6 className='more-data p2'>{i18next.t('MoreInformation')}</h6>
	);

	const allHaveAsset = highlightEquip?.every(item =>
		item.asModel.has('assets')
	);

	// critAir in FRA
	const engineType = engine.str('engine_type');
	let critAir;

	if (engineType === 'Electric') {
		critAir = 'bev';
	} else if (
		engineType === 'HybridPetrol' ||
		engineType === 'Petrol' ||
		engineType === 'Gas'
	) {
		critAir = 1;
	} else if (engineType === 'Diesel') {
		critAir = 2;
	}

	return (
		<>
			<div className='upper-table overview'>
				{
					<div
						className={`${engine.str('engine_type') === 'Electric' ? 'electric ' : engine.str('engine_type') === 'HybridPetrol' ? '' : 'petrol '}power`}
					>
						{setup?.market.name === 'AUS' &&
						engine.str('max_output_kw') &&
						engine.str('max_torque') ? (
							<UpperTableElement
								headline={'OverviewBoxPower'}
								value1={engine.str('max_output_kw', '-')}
								unit1={i18next.t('FuelPowerUnit')}
								value2={engine.str('max_torque', '-')}
								unit2={i18next.t('TorqueUnit')}
							/>
						) : !(
								engine.str('max_output_kw') &&
								engine.str('max_output_ps')
						  ) ? (
							<UpperTableElement
								headline={'OverviewBoxPower'}
								value1={
									engine.str('engine_type') === 'Electric'
										? engine.str('fuel_power2', '-')
										: engine.str('fuel_power', '-')
								}
								unit1={i18next.t('FuelPowerUnit')}
								value2={
									engine.str('engine_type') === 'Electric'
										? engine.str('fuel_power2_PS')
										: engine.str('fuel_power_PS')
								}
								unit2={i18next.t('FuelPowerPSUnit')}
							/>
						) : (
							<UpperTableElement
								headline={'OverviewBoxSystemPower'}
								value1={engine.str('max_output_kw', '-')}
								unit1={i18next.t('FuelPowerUnit')}
								value2={engine.str('max_output_ps')}
								unit2={i18next.t('FuelPowerPSUnit')}
							/>
						)}
					</div>
				}
				{engine.str('engine_type') === 'Electric' &&
				engine.has('battery_charging_dc_100') ? (
					<UpperTableElement
						headline={'OverviewBoxChargingTime'}
						value1={engine.str('battery_charging_dc_100', '-')}
						unit1={i18next.t('ChargingTimeUnit')}
					/>
				) : engine.str('engine_type') === 'Electric' &&
				  engine.has('battery_charging_ac_100') ? (
					<UpperTableElement
						headline={'OverviewBoxChargingTime'}
						value1={engine.str('battery_charging_ac_100', '-')}
						unit1={i18next.t('ChargingTimeUnit')}
					/>
				) : engine.has('acceleration') ? (
					<UpperTableElementNumber
						headline={'OverviewBoxAcceleration'}
						value={engine.float('acceleration', NaN)}
						unit={'AccelerationUnit'}
					/>
				) : engine.has('top_speed') ? (
					<UpperTableElement
						headline={'OverviewBoxTopSpeed'}
						value1={engine
							.float('top_speed', NaN)
							.toLocaleString(i18next.language)}
						unit1={i18next.t('SpeedUnit')}
					/>
				) : engine.has('motor_drive') ? (
					<UpperTableElement
						headline={'OverviewBoxMotorDrive'}
						value1={engine.str('motor_drive', '-')}
					/>
				) : engine.has('motor_gear') ? (
					<UpperTableElement
						headline={'OverviewBoxMotorGear'}
						value1={engine.str('motor_gear', '-')}
					/>
				) : engine.str('engine_type') === 'Electric' ? (
					<UpperTableElement
						headline={'OverviewBoxFuelType'}
						value1={engine.str('fuel2', '-')}
					/>
				) : engine.str('engine_type') === 'HybridPetrol' ? (
					<UpperTableElement
						headline={'OverviewBoxFuelType'}
						value1={i18next.t(engine.str('engine_type'))}
					/>
				) : (
					<UpperTableElement
						headline={'ModelYear'}
						value1={contentModel.float('modelyear')}
					/>
				)}
				{setup.market.name == 'FRA' ? (
					//FRA order of tiles is different: critAit comes last but consumption combined should still be visible
					<div
						onClick={() =>
							addOverlay(store, OverlayEnum.Consumption)
						}
					>
						<UpperTableElementNedcWltp
							headline={'OverviewBoxConsumption'}
							value={'xxx_consumption_combined'}
							unit={`${
								engine.str('engine_type') === 'Electric'
									? 'ElectricConsumptionUnit'
									: engine.str('engine_type') === 'Gas'
										? 'GasConsumptionUnit'
										: 'FuelConsumptionUnit'
							}`}
							model={vehicleDataModel}
							engine={engine}
							add={moreData}
						/>
					</div>
				) : engine.str('engine_type') === 'Electric' ||
				  engine.str('engine_type') === 'HybridPetrol' ? (
					<UpperTableElementNedcWltp
						headline={'OverviewBoxRangeCombined'}
						value={'xxx_range_combined'}
						unit={i18next.t('LengthUnit')}
						model={vehicleDataModel}
					/>
				) : (
					<UpperTableElement
						headline={'OverviewBoxFuelType'}
						value1={i18next.t(engine.str('engine_type'))}
					/>
				)}
				{setup.market.name == 'FRA' ? (
					<UpperTableElement headline={'CritAir'} svg={critAir} />
				) : (
					<div
						onClick={() =>
							addOverlay(store, OverlayEnum.Consumption)
						}
					>
						<UpperTableElementNedcWltp
							headline={'OverviewBoxConsumption'}
							value={'xxx_consumption_combined'}
							unit={`${
								engine.str('engine_type') === 'Electric'
									? 'ElectricConsumptionUnit'
									: engine.str('engine_type') === 'Gas'
										? 'GasConsumptionUnit'
										: 'FuelConsumptionUnit'
							}`}
							model={vehicleDataModel}
							engine={engine}
							add={moreData}
						/>
					</div>
				)}

				{usedCar && (
					<>
						<UpperTableElement
							headline={'InitialRegistration'}
							value1={new Date(
								contentModel.obj('initialreg').Timestamp
							).toLocaleDateString(i18next.language, {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit',
							})}
						/>
						<UpperTableElement
							headline={'MileageUsedCar'}
							value1={contentModel
								.float('mileage', NaN)
								.toLocaleString(i18next.language, {
									useGrouping: true,
								})}
							unit1={i18next.t('LengthUnit')}
						/>
						<UpperTableElement
							headline={'NextGeneralInspection'}
							value1={
								new Date(
									contentModel.str('generalinspection', '-')
								).toLocaleDateString(i18next.language, {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit',
								}) === 'Invalid Date'
									? contentModel.str('generalinspection', '-')
									: new Date(
											contentModel.str(
												'generalinspection',
												'-'
											)
										).toLocaleDateString(i18next.language, {
											year: 'numeric',
											month: '2-digit',
											day: '2-digit',
										})
							}
						/>
						<UpperTableElement
							headline={'WarrantyType'}
							value1={
								contentModel.bool('warranty')
									? i18next.t('WarrantyTrue')
									: '-'
							}
						/>
						<UpperTableElement
							headline={'PreviousOwners'}
							value1={contentModel.str('pown', '-')}
						/>
						<UpperTableElement
							headline={'ModelYear'}
							value1={contentModel.float('modelyear')}
						/>
					</>
				)}
			</div>

			{allHaveAsset ? (
				highlightEquip.map((equipItem, index) => (
					<div
						className='teaser-box'
						key={`teaser-box-${index}`}
						onClick={() => addOverlay(store, equipItem)}
					>
						{equipItem.asModel.has('assets') && (
							<>
								<TeaserImage
									equipItem={equipItem}
									iconVideo={'#play-circle'}
								/>
								<div className='teaser-box-right'>
									<p className='small category'>
										{(equipItem.asModel.target
											.equipcategory &&
											equipItem.asModel.target
												.equipcategory[
												i18next.language
											]) ||
											equipItem.asModel.str(
												'equipcategory'
											)}
									</p>
									<div className='truncate'>
										<h3>
											{(equipItem.asModel.target
												.layertitle &&
												equipItem.asModel.target
													.layertitle[
													i18next.language
												]) ||
												equipItem.asModel.str(
													'layertitle'
												)}
										</h3>
										<p
											className='long'
											dangerouslySetInnerHTML={checkHtml(
												(equipItem.asModel.target[
													'teasertext'
												] &&
													equipItem.asModel.target[
														'teasertext'
													][i18next.language]) ||
													equipItem.asModel.str(
														'teasertext'
													),
												i18next.t('ClickMe')
											)}
										/>
									</div>
									<button className='small'>
										{i18next.t('LearnMore')}
									</button>
								</div>
							</>
						)}
					</div>
				))
			) : (
				<div className='highlight-table'>
					<div className='table-element headline'>
						<h4 className='bold'>
							{i18next.t('EquipmentHighlights')}
						</h4>
					</div>
					{highlightEquip.map((equipItem, index) => {
						const item = equipItem.asModel;
						const showIcon = showInfoIcon(equipItem);
						return (
							<div
								key={`table-element-${index}`}
								className={`table-element${
									showIcon ? ' with-end' : ''
								}`}
								onClick={() => {
									if (showIcon) {
										addOverlay(store, equipItem);
									}
								}}
							>
								<p className='short'>
									{(item.target.layertitle &&
										item.target.layertitle[
											i18next.language
										]) ||
										item.str('layertitle')}
								</p>
								{showIcon && (
									<div className='icon-24 info-icon'>
										<svg>
											<use xlinkHref='#system-info' />
										</svg>
									</div>
								)}
							</div>
						);
					})}
				</div>
			)}
		</>
	);
}
